.not-found-section {
  background-color: #ffebee;

  .container {
    box-sizing: border-box;
    padding: 1rem 10%;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 540px;
    min-height: calc(100vh - 62px);

    h1 {
      font-weight: bold;
      margin: 0 auto 2rem;
      color: var(--secondary);
      font-size: 2.5rem !important;
    }
    p {
      font-size: 1.5rem !important;
      margin: 2rem auto 0;
    }

    a {
      font-weight: bold !important;
      font-size: 1.5rem !important;
      margin-left: 5px;
    }

    .notfound-thumb {
      width: 100%;
      max-width: 400px;
      margin-top: 1rem;
    }

    // @media (max-width: 768px) {
    //   min-height: 540px;
    //   height: calc(100vh - 187px);
    // }
  }
}
