:root {
  --primary: #283593;
  --secondary: #e91e63;
  /* --secondary: #f9a825; */
}

html {
  scroll-behavior: smooth;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  padding: 2.5rem 0rem;

  @media (max-width: 767px) {
    padding: 2rem;
  }
}

.underline {
  margin-top: -25px;
  width: 80px;
  filter: drop-shadow(0px 1px 0px var(--secondary));
}

h2,
.h2 {
  font-size: 35px;
  color: var(--primary);
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 25px;
  }
}

h5,
.h5 {
  font-weight: 500;
}

.circled-icon {
  margin-right: 0.5rem;
  background-color: var(--secondary);
  color: #fff;
  padding: 0.75rem;
  font-size: 1.25rem;
  border-radius: 50%;

  @media (max-width: 1023px) {
    margin-right: 0rem;
  }
}

.gray-bg {
  background-color: #f3f5f6;
}

main {
  ul,
  ol {
    li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.primary-text {
  color: var(--primary);
}
