.why-us-box {
  text-align: center;
  max-width: 90%;
  margin: auto;
  margin-right: 0px;
  padding: 25px;
  background-color: #f6f7fd;

  > p {
    &:nth-child(1) {
      color: var(--primary);
      font-size: 8em;
    }

    &:nth-child(2) {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: -1rem;
      margin-bottom: 0;
    }
  }

  @media (max-width: 1023px) {
    max-width: 100%;
    margin-top: 2rem;
  }
}

.why-us-list {
  list-style: none;
  margin-bottom: 0px;

  > li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: 500;

    > i {
      font-size: 1rem;
      margin-right: 1rem;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--secondary);
      color: #fff;
      border-radius: 50%;
    }
  }

  @media (max-width: 1023px) {
    padding: 0px;
  }
}

.why-us-contact {
  display: flex;
  align-items: center;

  > div {
    &:nth-child(2) {
      margin-left: 1rem;

      > b {
        color: var(--secondary);
        display: block;
        font-size: 1.25rem;
      }
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > div {
      text-align: center;

      .coccoc-alo-phone {
        margin: auto;
      }

      flex: 0 0 100%;
      min-width: 100%;
    }
  }
}

.coccoc-alo-ph-circle {
  width: 130px;
  height: 130px;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  // opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-phone {
  background-color: transparent;
  width: 130px;
  height: 130px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
  background-color: rgba(0, 175, 242, 0.5);
  // opacity: 0.75 !important;
}

.coccoc-alo-ph-circle-fill {
  width: 85px;
  height: 85px;
  top: 22.5px;
  left: 22.5px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  // opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-ph-img-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.25rem;

  width: 50px;
  height: 50px;
  top: 40px;
  left: 40px;
  position: absolute;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  // opacity: 0.7;
  -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
  background-color: var(--primary);
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
  border-color: var(--primary);
  // opacity: 0.5;
}

@-moz-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-moz-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-o-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}
