header {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.navbar-light {
  .navbar-brand {
    color: var(--primary);
    font-weight: bold;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    > img {
      max-width: 50px;
      margin-right: 0.5rem;
    }

    i {
      vertical-align: middle;
      color: var(--secondary);
      margin-right: 0.5rem;
    }

    &:hover,
    &:focus {
      color: var(--primary);

      i {
        color: var(--secondary);
      }
    }
  }

  .navbar-nav {
    .nav-link {
      color: var(--primary);
      transition: all 0.15s ease-in-out;
      font-weight: bold;

      &:focus,
      &:hover {
        color: var(--secondary);
      }

      &.active {
        color: var(--secondary);
      }

      &.nav-contact-btn {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: var(--secondary);
        cursor: initial;

        > i {
          font-size: 1.5rem;
          vertical-align: middle;
          margin-right: 0.5rem;
        }
      }

      @media (min-width: 768px) {
        padding: 0.5rem 1.5rem;
        text-align: center;
      }
    }
  }
}

.navbar-collapse {
  transition: all 0.25s ease;
  overflow: hidden;
  text-align: center;

  @media (min-width: 992px) {
    height: auto !important;
  }
}

.bg-light {
  background-color: #ffffff !important;
}
