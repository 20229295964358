.other-services {
  a {
    color: #000000;
    font-weight: 500;
    margin-bottom: 1rem;
    display: block;

    &:hover,
    &:focus {
      font-weight: bold;
    }
  }

  // > p {
  //   > span {
  //     font-weight: 500;
  //     margin: 2rem;
  //     line-height: 2.25rem;

  //     &:hover {
  //       font-weight: bold;
  //     }

  //     @media (max-width: 1023px) {
  //       margin: 0;
  //       display: block;
  //     }
  //   }
  // }
}

.service_thumb_list {
  > div {
    margin-bottom: 1rem;

    .card {
      text-decoration: none;
      color: #000000;
      transition: all 0.25s ease-in-out;
    }

    .card-title {
      margin: 0px;
      font-size: 1rem;
    }
  }

  &:hover,
  &:focus {
    > div {
      > .card {
        transform: scale(0.95);
        filter: grayscale(0.5);

        &:hover,
        &:focus {
          transform: scale(1.05);
          filter: grayscale(0);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
          z-index: 999;
        }
      }
    }
  }
}

.sub-heading {
  margin-bottom: 1rem;
}
