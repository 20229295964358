.accordion {
  .btn:not(:disabled):not(.disabled) {
    font-weight: normal;
    padding: 0px;
    transition: all 0.15s ease-in-out;

    &:hover,
    &:focus {
      padding-left: 0.5rem;
    }
  }
}
