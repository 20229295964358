.carousel-control-next,
.carousel-control-prev {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    transition: all 0.25s ease;
    padding: 1.5rem;
    background-position: center;
    background-size: 50%;
    border-radius: 50%;
  }

  &:hover,
  &:focus {
    opacity: 1;

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background-color: var(--primary);
    }
  }
}

main .carousel-caption {
  text-shadow: 0px 2px 5px #000000;
  display: block !important;

  > h3 {
    font-size: 2rem;
  }

  @media (max-width: 767px) {
    > h3 {
      display: block;
      font-size: 1.25rem !important;
    }

    > p {
      display: none;
    }
  }
}
